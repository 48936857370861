@keyframes fadeinout {
  0%, to {
    opacity: 0;
  }

  10% {
    opacity: .33;
  }
}

.bui .bui-badge.bui-badge-enter {
  transform-origin: center;
  animation: badgeEnter 1.44s linear;
}

.bui .bui-badge.bui-badge-exit {
  transform-origin: center;
  animation: badgeExit .36s ease-out forwards;
}

@keyframes badgeEnter {
  0% {
    transform: scale(0);
  }

  12% {
    transform: scale(1.2);
  }

  24% {
    transform: scale(.75);
  }

  36% {
    transform: scale(1.1);
  }

  48% {
    transform: scale(.9);
  }

  54% {
    transform: scale(1);
  }
}

@keyframes badgeExit {
  0% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.2);
  }

  to {
    transform: scale(0);
  }
}

.bui .spinner {
  transform-origin: center;
  animation: rotator 1.44s linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(270deg);
  }
}

.bui .loading {
  stroke-dasharray: 112;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.44s cubic-bezier(.25, 0, .36, 1) infinite;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 112px;
  }

  50% {
    stroke-dashoffset: 26px;
    transform: rotate(135deg);
  }

  to {
    stroke-dashoffset: 112px;
    transform: rotate(450deg);
  }
}

.bui input[type="date"] {
  height: auto;
  margin: 0;
  padding: 23px 15px 0;
}

.bui input[type="date"]::-webkit-inner-spin-button {
  display: none;
}

.bui input[type="date"]::-webkit-datetime-edit-day-field, .bui input[type="date"]::-webkit-datetime-edit-month-field, .bui input[type="date"]::-webkit-datetime-edit-text, .bui input[type="date"]::-webkit-datetime-edit-year-field {
  display: none;
}

.bui input[type="date"]::-webkit-calendar-picker-indicator {
  color: var(--on-surface-light);
}

.bui input[type="date"]::-webkit-calendar-picker-indicator:hover {
  color: var(--on-surface);
  cursor: pointer;
  background-color: #0000;
}

.bui input[type="date"]::-webkit-calendar-picker-indicator:active {
  color: var(--accent);
}

.bui.bui-showdate input[type="date"]::-webkit-datetime-edit-day-field, .bui.bui-showdate input[type="date"]::-webkit-datetime-edit-month-field, .bui.bui-showdate input[type="date"]::-webkit-datetime-edit-text, .bui.bui-showdate input[type="date"]::-webkit-datetime-edit-year-field {
  display: inline-block;
}

.bui-tabs::-webkit-scrollbar {
  display: none;
}

.bui-tabs {
  -ms-overflow-style: none;
}

.bui {
  -webkit-tap-highlight-color: #0000;
  --hue: 328;
  --saturation: 100%;
  --lightness: 62%;
  --ok-hue: 124;
  --error-hue: 0;
  --warning-hue: 36;
  font-family: helvetica neue, arial, sans-serif;
}

.bui .heading-1 {
  font-size: 6em;
  font-weight: 300;
  line-height: 1.04167;
}

.bui .heading-2 {
  font-size: 3.75em;
  font-weight: 300;
  line-height: 1.03333;
}

.bui .heading-3 {
  font-size: 3em;
  font-weight: normal;
  line-height: 1.04167;
}

.bui .heading-4 {
  font-size: 2.125em;
  font-weight: normal;
  line-height: 1.05888;
}

.bui .heading-5 {
  font-size: 1.5em;
  font-weight: normal;
  line-height: 1.08333;
}

.bui .heading-6 {
  font-size: 1.25em;
  font-weight: 400;
  line-height: 1.1;
}

.bui .heading-7 {
  font-size: 1em;
  font-weight: 600;
  line-height: 1.25;
}

.bui .body-1 {
  font-size: 1em;
  line-height: 1.25;
}

.bui .body-2 {
  font-size: .875em;
  line-height: 1.42857;
}

.bui .caption {
  font-size: .75em;
  line-height: 1.66667;
}

.bui .overline {
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: .625em;
  line-height: 1;
}

.bui-show-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
  background-color: none;
}

.bui-show-scroll::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: 3px;
}

.bui-show-scroll-square::-webkit-scrollbar-thumb {
  border-radius: 0;
}

.bui-show-scroll::-webkit-scrollbar-corner {
  background: var(--surface);
}

.bui-show-keyboard-focus:focus-visible {
  outline-offset: 3px;
  outline: 3px solid var(--accent);
}

.bui-show-keyboard-focus-inside:focus-visible {
  outline-offset: -3px;
  outline: 3px solid var(--accent);
}

.bui a, .bui a:visited {
  color: var(--accent-text);
  -webkit-tap-highlight-color: hsla(var(--hue), var(--saturation), 62%, .1);
}

.bui a:focus {
  outline-offset: 3px;
  outline: 3px solid var(--accent);
}

.bui a.discrete, .bui a.discrete:visited {
  color: var(--on-surface-light);
}

.bui a.text, .bui a.text:visited {
  color: var(--on-surface);
}

.bui button {
  font-family: helvetica neue, arial, sans-serif;
}

.bui input {
  -webkit-appearance: none;
  font-family: helvetica neue, arial, sans-serif;
}

.bui::-moz-focus-inner {
  border: 0;
}

.bui textarea {
  font-family: helvetica neue, arial, sans-serif;
}

.bui input[type="search"] {
  height: 54px;
}

.bui input[type="search"]::-webkit-search-decoration, .bui input[type="search"]::-webkit-search-cancel-button, .bui input[type="search"]::-webkit-search-results-button, .bui input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.bui input:-webkit-autofill {
  -webkit-text-fill-color: var(--on-surface);
  -webkit-box-shadow: 0 0 0px 1000px var(--autocomplete) inset;
  box-shadow: 0 0 0px 1000px var(--autocomplete) inset;
  border: none;
}

.bui input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.bui {
  --background: hsl(var(--hue) 20% 8%);
  --on-background: hsl(var(--hue) 20% 90%);
  --on-background-light: hsl(var(--hue) 10% 55%);
  --container-background: hsla(var(--hue), 15%, 17%, .8);
  --container-outline: hsla(var(--hue), 15%, 29%, .8);
  --container-outline-lighter: hsla(var(--hue), 15%, 23%, .8);
  --data-table-hover: hsl(var(--hue), 20%, 15%);
  --data-table-selected: var(--accent-light);
  --autocomplete: hsl(55, calc(var(--saturation) * .86), 14%);
  --surface: hsl(var(--hue) 20% 8%);
  --surface-floating: hsl(var(--hue) 5% 12%);
  --surface-alternative: hsl(var(--hue) 20% 15%);
  --surface-highlight: hsl(var(--hue) var(--saturation) 75%);
  --on-surface: hsl(var(--hue) 20% 90%);
  --on-surface-light: hsl(var(--hue) 10% 55%);
  --on-surface-lighter: hsl(var(--hue) 10% 35%);
  --inverted-surface: hsl(var(--hue) 20% 30%);
  --inverted-surface-highlight: white;
  --on-inverted-surface: hsl(var(--hue) 20% 95%);
  --ok: hsl(var(--ok-hue) calc(var(--saturation) * .48) 40%);
  --ok-text: hsl(var(--ok-hue) calc(var(--saturation) * .38) 45%);
  --on-ok: white;
  --error: hsl(var(--error-hue) var(--saturation) 49%);
  --error-text: hsl(var(--error-hue) var(--saturation) 60%);
  --on-error: white;
  --error-highlight: white;
  --warning: hsl(var(--warning-hue) calc(var(--saturation) * .97) 58%);
  --warning-text: hsl(var(--warning-hue) calc(var(--saturation) * .95) 35%);
  --on-warning: hsl(var(--hue) 10% 10%);
  --accent: hsl(var(--hue) var(--saturation) calc(var(--lightness) * 1.12903) );
  --accent-text: hsl(var(--hue) var(--saturation) calc(var(--lightness) * 1.1) );
  --accent-on-inverted-surface: hsl(var(--hue) var(--saturation) calc(var(--lightness) * 1.2) );
  --accent-surface: hsl(var(--hue) var(--saturation) calc(var(--lightness) * .935484) );
  --accent-light: hsl(var(--hue) var(--saturation) calc(var(--lightness) * .483871) );
  --accent-highlight: white;
  --on-accent: white;
  --selection-control: hsl(var(--hue) 10% 30%);
  --on-selection-control: hsl(var(--hue) 20% 95%);
  --selection-control-selected: var(--accent-surface);
  --on-selection-control-selected: var(--on-accent);
  --badge: var(--error);
  --navigation: var(--surface);
  --on-navigation-resting: var(--on-surface-light);
  --on-navigation-active: var(--accent);
  --navigation-highlight: var(--surface-highlight);
  --navigation-inverted: var(--inverted-surface);
  --navigation-inverted-highlight: white;
  --on-navigation-inverted-resting: hsl(var(--hue) 20% 75%);
  --on-navigation-inverted-active: var(--inverted-surface-highlight);
  --navigation-accent: var(--accent-surface);
  --navigation-accent-highlight: white;
  --on-navigation-accent-resting: hsl(var(--hue) 100% 85%);
  --on-navigation-accent-active: white;
  --modal-background: hsla(var(--hue), 20%, 2%, .88);
  --box-shadow-color: hsla(var(--hue), 20%, 5%, .9);
  --scrollbar-thumb: hsl(var(--hue) 10% 30%);
  --button-contained-font-weight: normal;
}

body {
  background: var(--background);
  color: var(--on-background);
  margin: 0;
  padding: 0;
  font-family: Helvetica Neue, Arial, sans-serif;
}

* {
  box-sizing: border-box;
}

select, option {
  color: var(--on-surface);
  background: var(--container-background);
}

.bui {
  --hue: 125;
  --saturation: 93%;
  --lightness: 62%;
  --background: hsl(var(--hue) 1% 6%);
  --surface: hsl(var(--hue) 1% 6%);
  --surface-floating: hsl(var(--hue) 1% 11%);
  --container-background: hsla(var(--hue), 5%, 16%, .8);
  --container-outline: hsla(var(--hue), 5%, 26%, .8);
  --container-outline-lighter: hsla(var(--hue), 5%, 23%, .8);
  --data-table-hover: hsl(var(--hue), 5%, 13%);
  --data-table-selected: hsl(var(--hue), 75%, 22%);
  --inverted-surface: hsl(var(--hue) 10% 20%);
  --surface-alternative: hsl(var(--hue) 5% 13%);
  --surface-highlight: hsl(var(--hue) 40% 75%);
  --accent: hsl(var(--hue) var(--saturation) calc(var(--lightness) * 1.05) );
  --accent-text: hsl(var(--hue) var(--saturation) calc(var(--lightness) * 1.05) );
  --accent-surface: hsl(var(--hue) var(--saturation) calc(var(--lightness) * 1) );
  --accent-light: hsl(var(--hue) calc(var(--saturation) * .37) calc(var(--lightness) * .4) );
  --on-accent: #000;
  --accent-highlight: hsl(var(--hue) 50% 25%);
  --selection-control: hsl(var(--hue) 10% 36%);
  --selection-control-selected: var(--accent-surface);
  --on-selection-control-selected: hsl(var(--hue) calc(var(--saturation) * .37) calc(var(--lightness) * .55) );
  --on-selection-control-selected: hsl(var(--hue) calc(var(--saturation) * .37) calc(var(--lightness) * .35) );
  --error: hsl(var(--error-hue) var(--saturation) 46%);
  --error-text: hsl(var(--error-hue) var(--saturation) 54%);
  --error-highlight: hsla(var(--error-hue), 50%, 100%, .75);
  --ok-text: hsl(var(--ok-hue) calc(var(--saturation) * .38) 45%);
  --warning-text: hsl(var(--warning-hue) calc(var(--saturation) * .7) 48%);
  --navigation-accent-highlight: #000;
  --on-navigation-accent-resting: hsl(var(--hue) 100% 25%);
  --on-navigation-accent-active: #000;
  --scrollbar-thumb: hsl(var(--hue) calc(var(--saturation) * .2) calc(var(--lightness) * .8) );
  --button-contained-font-weight: 500;
}

/*# sourceMappingURL=index.1a8ac460.css.map */
